@font-face {
    font-family: 'Vonique 64';
    src: local('Vonique 64'), url(./fonts/Vonique64.ttf) format('truetype');
  }

  h1{
    font-family: 'Vonique 64', sans-serif;
    color:#6063fe !important;
}

.mb-32, .m-0, #emailBtn{
  font-family: 'Vonique 64', sans-serif;
}

.bd {
  margin-left:300px;
}

#spotLink {
  margin-left:66px;
}


